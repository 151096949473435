<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">题库订单</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl questionBankOrderList"
          style="display: flex; flex-direction: column; align-items: flex-start"
        >
          <div class="searchbox">
            <div title="订单编号" class="searchboxItem ci-full">
              <span class="itemLabel">订单编号:</span>
              <el-input
                v-model="selectDate.orderSn"
                type="text"
                size="small"
                placeholder="请输入订单编号"
                clearable
              />
            </div>
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-input
                v-model="selectDate.productName"
                type="text"
                size="small"
                placeholder="请输入题库名称"
                clearable
              />
            </div>
            <div title="买家姓名" class="searchboxItem ci-full">
              <span class="itemLabel">买家姓名:</span>
              <el-input
                v-model="selectDate.userName"
                type="text"
                size="small"
                placeholder="请输入买家姓名"
                clearable
              />
            </div>
          
          </div>
          <div class="searchbox" style="padding-top: 10px">
            <div title="订单状态" class="searchboxItem ci-full">
              <span class="itemLabel">订单状态:</span>
              <el-select
                size="small"
                v-model="selectDate.orderStatus"
                clearable
                placeholder="请选择订单状态"
              >
                <el-option
                  v-for="item in orderStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="支付方式" class="searchboxItem ci-full">
              <span class="itemLabel">支付方式:</span>
              <el-select
                size="small"
                v-model="selectDate.orderMethod"
                clearable
                placeholder="请选择支付方式"
              >
                <el-option
                  v-for="item in orderMethodList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="下单手机" class="searchboxItem ci-full">
              <span class="itemLabel">下单手机:</span>
              <el-select
                v-model="selectDate.orderPhone"
                clearable
                placeholder="请选择下单手机"
                size="small"
              >
                <el-option
                  v-for="item in orderPhoneList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            
          </div>
          <div class="searchbox" style="padding-top: 10px">
              <div title="买家手机号" class="searchboxItem ci-full">
              <span class="itemLabel" >买家手机号:</span>
              <el-input
                v-model="selectDate.mobile"
                type="text"
                size="small"
                placeholder="请输入买家手机号"
                clearable
              />
            </div>
            <div title="下单时间" class="searchboxItem ci-full">
              <span class="itemLabel" >下单时间:</span>
              <!-- <el-date-picker
                style="width: 230px;"
                v-model="selectDate.startTime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                size="small"
                placeholder="选择日期">
              </el-date-picker> -->
              <el-date-picker
                clearable
                style="width: 260px"
                size="small"
                format="yyyy-MM-dd"
                v-model="selectDate.startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df" style="padding-right: 15px">
              <el-button class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="Export()">导出</el-button>
            </div>
          </div>
        </div>
        <h4 style="padding:8px 10px">订单总收益：{{ moneySum }}元</h4>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="120"
                fixed
              />
              <el-table-column
                label="订单编号"
                align="left"
                show-overflow-tooltip
                prop="orderSn"
                width="200"
                fixed
              />
              <el-table-column
                label="题库名称"
                align="left"
                show-overflow-tooltip
                prop="productName"
                width="260"
              />
              <el-table-column
                label="题库类别"
                align="left"
                show-overflow-tooltip
                prop="questionCategory"
                width="260"
              />
              <el-table-column
                label="价格（元）"
                align="left"
                show-overflow-tooltip
                prop="productPrice"
              />
              <el-table-column
                label="实付金额（元）"
                align="left"
                show-overflow-tooltip
                prop="paymentMoney"
                width="150"
              />
              <el-table-column
                label="买家姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
              >
                <template slot-scope="scope">
                  {{ scope.row.userName }}</template
                >
              </el-table-column>
              <el-table-column
                label="买家手机号"
                align="right"
                width="180"
                show-overflow-tooltip
                prop="mobile"
              />
              <el-table-column
                label="下单手机"
                align="right"
                show-overflow-tooltip
                prop="orderPhone"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.orderPhone == "IOS" ? "苹果手机" : "安卓手机"
                  }}</template
                >
              </el-table-column>
              <el-table-column
                label="支付方式"
                align="right"
                show-overflow-tooltip
                prop="paymentMethod"
                width="150"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("ORDER_METHOD", scope.row.paymentMethod)
                  }}</template
                >
              </el-table-column>
              <el-table-column
                label="下单时间"
                align="right"
                show-overflow-tooltip
                width="140px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="订单状态"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.orderStatus 
                }}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    style="padding: 0 5px"
                    type="text"
                    size="mini"
                    @click="getDetails(scope.row.orderId)"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="订单详情"
      :visible.sync="detailDialog"
      width="35%"
      top="2%"
      center
      :before-close="dialogClose"
    >
      <div class="orderDetail">
        <div class="order_stu">
          <div>
            <span
              >当前订单状态：{{
                ruleForm.orderStatus 
              }}</span
            >
          </div>
        </div>
        <div class="order_form">
          <div class="df">
            <span>订单编号：</span>
            <span>{{ ruleForm.orderSn }}</span>
          </div>
          <div class="df">
            <span>下单时间：</span>
            <span>{{ ruleForm.createTime }}</span>
          </div>
          <div class="df">
            <span>下单手机：</span>
            <span>{{
              ruleForm.orderPhone == "IOS" ? "苹果手机" : "安卓手机"
            }}</span>
          </div>
          <div class="df">
            <span>支付单号：</span>
            <span>{{ ruleForm.thirdPartPaymentNumber || "--" }}</span>
          </div>
          <div class="df">
            <span>支付时间：</span>
            <span>{{ ruleForm.payTime || "--" }}</span>
          </div>
          <div class="df">
            <span>支付方式：</span>
            <span v-if="ruleForm.paymentMethod == 1">苹果内部支付</span>
            <span v-else-if="ruleForm.paymentMethod == 2">银联支付宝支付</span>
            <span v-else-if="ruleForm.paymentMethod == 3">银联微信支付</span>
            <span v-else-if="ruleForm.paymentMethod == 4">微信支付</span>
            <span v-else-if="ruleForm.paymentMethod == 9">积分支付</span>
            <span v-else>--</span>
          </div>
          <el-divider></el-divider>
          <div class="df">
            <span>买家姓名：</span>
            <span>{{ ruleForm.userName }}</span>
          </div>
          <div class="df">
            <span>手机号：</span>
            <span>{{ ruleForm.mobile }}</span>
          </div>
          <el-divider></el-divider>
          <div class="df">
            <span>题库名称：</span>
            <span style="flex: 1">{{ ruleForm.productName }}</span>
          </div>
          <div class="df">
            <span>有效期：</span>
            <span>{{ ruleForm.validDurationString || "--" }}</span>
          </div>
          <div class="df">
            <span>题库金额：</span>
            <span>￥{{ ruleForm.productPrice }}</span>
          </div>
          <el-divider></el-divider>
          <div class="df">
            <span
              >{{
                ruleForm.orderStatus != '未完成' ? "实付金额：" : "待付金额"
              }}：</span
            >
            <span style="color: red">￥{{ ruleForm.paymentMoney }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "questionBankOrderList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      detailDialog: false,
      selectDate: {
        orderSn: "",
        productName: "",
        userName: "",
        mobile: "",
        startTime: "",
        orderStatus: "",
        orderMethod: "",
        orderPhone: "",
      },
      orderStatusList: [
             {
          value: "1",
          label: "未完成",
        },
          {
          value: "2",
          label: "已完成",
        },
        {
          value: "3",
          label: "退款中",
        },
        {
          value: "4",
          label: "退款失败",
        },
        {
          value: "5",
          label: "已退款",
        },
       
      ],
      orderMethodList: [],
      orderPhoneList: [
        {
          value: "IOS",
          label: "苹果手机",
        },
        {
          value: "AND",
          label: "安卓手机",
        },
      ],
      ruleForm: {},
      moneySum:0, // 订单总收益
    };
  },
  created() {
    this.getOrderMethodList()
  },
  watch: {
    // $route: {
    //   handler: function(val, oldVal) {
    //     if (oldVal.path != "/web/StudentLearningRecordall") {
    //       this.areaId = "";
    //       this.screen = "";
    //       this.getData(-1);
    //     }
    //   },
    //   // 深度观察监听
    //   deep: true
    // },
    // classId: function(val) {
    //   if (val == "") {
    //     this.classListModel = [];
    //   }
    // }
  },
  methods: {
     getOrderMethodList() {
      const OrderMethodList = this.$setDictionary("ORDER_METHOD", "list") || [];
      let list = [];
      for (const key in OrderMethodList) {
        if(OrderMethodList[key] != '积分支付') {
           list.push({
          value: key,
          label: OrderMethodList[key],
        });
        }
       
      }
      this.orderMethodList = list;
    },
    // 详情
    doOrderDetail() {
      this.detailDialog = false;
    },
    // 弹窗关闭
    dialogClose() {
      this.detailDialog = false;
    },
    getparams(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.selectDate.orderSn) {
        params.orderSn = this.selectDate.orderSn;
      }
      if (this.selectDate.productName) {
        params.productName = this.selectDate.productName;
      }
      if (this.selectDate.userName) {
        params.userName = this.selectDate.userName;
      }
      if (this.selectDate.mobile) {
        params.mobile = this.selectDate.mobile;
      }
      if (this.selectDate.startTime) {
        params.createTime = this.selectDate.startTime[0];
        params.endTime = this.selectDate.startTime[1];
      }
      if (this.selectDate.orderStatus) {
        params.orderStatus = this.selectDate.orderStatus;
      }
      if (this.selectDate.orderMethod) {
        params.paymentMethod = this.selectDate.orderMethod;
      }
      if (this.selectDate.orderPhone) {
        params.orderPhone = this.selectDate.orderPhone;
      }
      return params;
    },
    // 列表
    getData(pageNum = 1) {
      this.doFetch(
        {
          url: "/order/main/order-main/pageList",
          params: this.getparams(pageNum),
          pageNum,
        },
        true,
        2
      );
      this.$post(
        "/order/main/order-main/getSum",
        this.getparams(),
        3000,
        true,
        2
      ).then((res) => {
        // ;
        if (res.status == "0") {
          this.moneySum = res.data;
        }
      });
    },
    // 导出
    Export() {
      this.$post(
        "/order/main/order-main/listExport",
        this.getparams(),
        3000,
        true,
        2
      ).then((res) => {
        // ;
        if (res.status == "0") {
          window.open(res.data);
        }
      });
    },
    getDetails(orderId) {
      this.detailDialog = true;
      this.$post("/order/main/order-main/getInfo", { orderId }, 3000, true, 2)
        .then((ret) => {
          console.log(ret);
          this.ruleForm = {
            ...ret.data,
          };
          console.log(this.ruleForm);
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 6) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 ;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.questionBankOrderList {
  .itemLabel {
    min-width: 6rem;
  }
}
.orderDetail {
  .order_stu {
    padding: 10px;
    background-color: rgb(255, 255, 204);
    border: 1px solid rgb(255, 204, 0);
    color: rgb(255, 51, 0);
  }
  .order_form {
    div.df {
      padding: 8px 0;
      span:first-child {
        width: 6rem;
        text-align: right;
        margin-right: 8px;
      }
    }
  }
}
</style>
